var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", tile: "", elevation: "0" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-sm-8 py-4",
          attrs: { color: "light_gray", tile: "", elevation: "0" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
                _c(
                  "span",
                  { staticClass: "text-sm-h5 text-body-1 font-weight-bold" },
                  [_vm._v(_vm._s(_vm.$t("label.register")))]
                ),
                _c("br"),
                _c(
                  "span",
                  { staticClass: "text-sm-h5 text-body-2 font-weight-bold" },
                  [_vm._v(_vm._s(_vm.content.className))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "my-6 pt-8 pb-10" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "11", lg: "9" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "px-md-6 py-6",
                      attrs: {
                        elevation: _vm.$vuetify.breakpoint.xsOnly ? 0 : 4,
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-h5 full-width font-weight-bold text-center mb-0",
                        },
                        [_vm._v(_vm._s(_vm.content.firstLine))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-h4 full-width font-weight-bold text-center mb-0",
                        },
                        [_vm._v(_vm._s(_vm.content.secondLine))]
                      ),
                      _vm.registrationIsOpen
                        ? [
                            _c("div", { staticClass: "text-center py-4" }, [
                              _c("img", {
                                staticClass: "registerClassImage",
                                attrs: { src: _vm.content.image },
                              }),
                            ]),
                            _c(
                              "v-form",
                              {
                                ref: "registrationFormRef",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.onSubmitRegistration.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("v-divider", { staticClass: "my-5" }),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "3" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("label.personalData")
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  sm: "6",
                                                  md: "4",
                                                },
                                              },
                                              [
                                                _c("app-form-field", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "label.fullNameAsPerNric"
                                                    ),
                                                    placeholder:
                                                      _vm.$t("label.fullName"),
                                                    rules:
                                                      _vm.$validation.nameRules(),
                                                  },
                                                  model: {
                                                    value: _vm.formData.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "formData.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  sm: "6",
                                                  md: "4",
                                                },
                                              },
                                              [
                                                _c("app-form-field", {
                                                  attrs: {
                                                    label:
                                                      _vm.$t("label.email"),
                                                    placeholder:
                                                      "eg: abc@gmail.com",
                                                    rules:
                                                      _vm.$validation.emailRules(),
                                                  },
                                                  model: {
                                                    value: _vm.formData.email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "email",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.email",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  sm: "6",
                                                  md: "4",
                                                },
                                              },
                                              [
                                                _c("app-form-field", {
                                                  attrs: {
                                                    prefix: "+60",
                                                    z: "",
                                                    placeholder:
                                                      "111 ---- ----",
                                                    label:
                                                      _vm.$t(
                                                        "label.mobileNumber"
                                                      ),
                                                    rules:
                                                      _vm.$validation.mobileNumberRules(),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData
                                                        .contactNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "contactNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.contactNumber",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "my-5" }),
                                _c(
                                  "div",
                                  { staticClass: "text-md-right text-center" },
                                  [
                                    _c("app-button", {
                                      attrs: {
                                        title: _vm.$t("label.rsvpNow"),
                                        type: "default",
                                        action: _vm.onSubmitRegistration,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Dialog", {
        attrs: {
          maxWidth: 350,
          title: _vm.dialogData.title,
          dialogShow: _vm.shouldDialogShow,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "dialog-body text-center" },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.dialogData.message))]),
                    _vm._l(_vm.dialogData.details, function (detail, i) {
                      return _c(
                        "p",
                        { key: i, staticClass: "caption secondary--text mb-1" },
                        [_vm._v(_vm._s(detail))]
                      )
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "rounded-lg font-weight-bold white--text text-body-1 px-8 mt-6",
                        attrs: {
                          color: "unbox_black",
                          height: "48px",
                          type: "button",
                        },
                        on: { click: _vm.onDialogClose },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.close")))]
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }